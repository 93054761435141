import { buildApiClientFromNextJsContext } from '@/api/apiClient';
import { SearchResult } from '@/types/search/searchresult';
import { CourseSession } from '@/types/core/CourseSession';
import { ApiClientOptions } from '@/types/apiclient';
import { User } from '@/types/core/User';

export async function getCourseSession(
  idCourseSession: string,
  options?: ApiClientOptions
): Promise<CourseSession> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url =
    `/api/course_session/${idCourseSession}` +
    `?fields[course_session]=status,certificationFilePath,certificationDate,score,feedbackRate,feedbackComment,createdAt`;

  const response = await apiClient(url);
  return response.json();
}

export async function getUserCourseSession(
  idCourse: string,
  options?: ApiClientOptions
): Promise<CourseSession | null> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);

  const url =
    `/api/courses/${idCourse}/mySession` +
    `?fields[course_session]=status,createdAt,updatedAt,certificationVisible,resettable` +
    '&includes=currentChapter,evaluationAttempts';
  const response = await apiClient(url);
  if (!response.ok) {
    return null;
  }
  return response.json();
}

/**
 * Get all sessions from courses for the current user
 * @param options ApiClientOptions
 */
export async function getUserCoursesSessions(
  options?: ApiClientOptions
): Promise<CourseSession[]> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url =
    `/api/users/me` +
    `?fields[course_session]=status,createdAt` +
    `?fields[course]=title` +
    '&includes=coursesSessions,currentChapter,evaluationAttempts,coursesSessions.course';
  const response = await apiClient(url);
  // If the user is not logged in, we return an empty array
  if (response.status === 404) return [];
  const user: User = await response.json();
  return user.coursesSessions || [];
}

export async function getCourseSessionCertificate(
  idCourseSession: string,
  options?: ApiClientOptions
): Promise<Blob> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url = `/api/course_session/${idCourseSession}/certificate`;
  const response = await apiClient(url);
  return response.blob();
}

export async function setCourseSessionCertificateVisibility(
  idCourseSession: string,
  visibility: 'public' | 'private',
  options?: ApiClientOptions
): Promise<Response> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url = `/api/course_session/${idCourseSession}/certificate/${visibility}`;
  return await apiClient(url, {
    method: 'POST',
  });
}

export async function createCourseSession(
  idCourse: string,
  options?: ApiClientOptions
): Promise<Response> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url = `/api/course_session`;
  return await apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      courseId: idCourse,
    }),
  });
}

export async function progressCourseSession(
  idCourseSession: string,
  options?: ApiClientOptions
): Promise<Response> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url = `/api/course_session/${idCourseSession}/progress`;
  return await apiClient(url, {
    method: 'PUT',
  });
}

export async function resetCourseSessionEvaluation(
  idCourseSession: string,
  options?: ApiClientOptions
): Promise<Response> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url = `/api/course_session/${idCourseSession}/evaluation/reset`;
  return await apiClient(url, {
    method: 'PUT',
  });
}

export async function removeCourseSession(
  idCourseSession: string,
  options?: ApiClientOptions
): Promise<Response> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url = `/api/course_session/${idCourseSession}`;
  return await apiClient(url, {
    method: 'DELETE',
  });
}

export async function resetCourseSession(
  idCourseSession: string,
  options?: ApiClientOptions
): Promise<Response> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url = `/api/course_session/${idCourseSession}/reset`;
  return await apiClient(url, {
    method: 'PUT',
  });
}
